.cnt_call {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding-bottom: 10px;
    position: relative;
    z-index: 5000;
    font-family: 'Raleway', sans-serif;
}

.cnt_svg {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
}

.svg-icons {
    width: 15px;
    margin: 0 7px;
    transition: all 500ms ease;
}

.svg-icons:hover {
    transform: scale(1.3);
}

.call_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    background-color: var(--orange-prim);
    border: 0;
    color: white;
    font-weight: 800;
    font-style: normal;
    padding: 5px 10px;
    border-radius: 7px;
    text-decoration: inherit;
    font-size: 14px;
}

.svg-icon-phone {
    width: 23px;
    margin: 0 10px 0 -5px;
}

@media screen and (min-width: 1000px) {
    .cnt_call {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 60px;
    }
    
    .cnt_svg {
        height: 40px;
        margin-left: 150px;
        margin-top: 18px;
    }
    
    .svg-icons {
        width: 18px;
        margin: 0 7px;
    }
    
    .call_button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        background-color: var(--orange-prim);
        border: 0;
        color: white;
        font-weight: 800;
        font-style: normal;
        padding: 8px 18px;
        border-radius: 7px;
        text-decoration: inherit;
        font-size: 20px;
        margin-right: 150px;
        margin-top: 15px;
    }
    
    .svg-icon-phone {
        width: 23px;
        margin: 0 10px 0 -5px;
    }
}