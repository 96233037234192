.cnt_workteam-image {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: black;
    height: 350px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.cnt_wt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.workteam-text-title {
    color: white;
    font-size: 25px;
    letter-spacing: -0.06em;
    font-weight: 900;
}

.workteam-title {
    color: white;
    letter-spacing: -0.06em;
    font-size: 60px;
    margin: 0;
}

.cnt-workteam-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.workteam-text {
    font-family: 'Raleway', sans-serif;
    width: 80%;
    font-size: 21px;
    letter-spacing: -0.03em;
    font-weight: 400;
}

.wtweight {
    font-weight: 600;
}

.background-wt {
    background-color: black;
}

.workteam-subtitle {
    font-size: 40px;
    letter-spacing: -0.06em;
    font-weight: 900;
}

.center {
    text-align: center;
}

.colorwt {
    color: white;
}

.collage {
    width: 100%;
}

@media screen and (min-width: 1000px) {
    .cnt_workteam-image {
        height: 450px;
    }
    
    .workteam-text-title {
        font-size: 65px;
        margin: 0;
    }
    
    .workteam-title {
        font-size: 70px;
        margin: 0;
    }
    
    .workteam-text {
        width: 70%;
        font-size: 30px;
        font-weight: 400;
    }
    
    .wtweight {
        font-weight: 600;
    }
    
    .background-wt {
        height: 300px;
    }
    
    .workteam-subtitle {
        font-size: 60px;
        letter-spacing: -0.06em;
        font-weight: 900;
    }
    
    .center {
        text-align: center;
    }
    
    .colorwt {
        color: white;
    }

    .collage {
        width: 40%;
    }
}