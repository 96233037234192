.cnt_welcome-partner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    background-color: black;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.subtitle-welcome-partner {
    color: white;
    width: 80%;
    margin: 10px 0;
    padding: 0;
    font-size: 28px;
    font-family: 'Raleway', sans-serif;
}

.title-welcome-partner {
    color: var(--orange-prim);
    font-size: 45px;
    width: 80%;
    letter-spacing: -0.06em;
    line-height: 0.9;
    margin: 0;
}

.cnt_complement-welcome-partner {
    background-color: #724309bf;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    flex-direction: column;
}

.complement-welcome-text-partner {
    margin: 0;
    padding: 30px 0;
    color: white;
    font-weight: 600;
    font-size: 20px;
    padding: 30px;
    letter-spacing: 0.9px;
    font-family: 'Raleway', sans-serif;
}

.cnt_information-partner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.information-partner-title {
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.06em;
}

.information-partner-text {
    font-size: 20px;
    width: 80%;
}

.logos-partner {
    width: 100%;
}

.cnt_information-partner-background {
    background-color: #1e3a4f;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cnt_information-partner-background-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.subtitle-information-partner-background {
    font-size: 48px;
    color: white;
    width: 70%;
    text-align: center;
}

.cnt_button-partner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000CC;
    height: 150px;
}

.button-partner {
    padding: 10px 60px;
    background-color: var(--orange-prim);
    margin: 30px;
    font-size: 23px;
    letter-spacing: -0.03em;
    border-radius: 10px;
    font-weight: 700;
    color: #000000CC;
}

@media screen and (min-width: 1000px) {
    .cnt_welcome-partner-text {
        height: 700px;
    }

    .subtitle-welcome-partner {
        font-size: 75px;
    }

    .title-welcome-partner {
        font-size: 110px;
    }

    .complement-welcome-text-partner {
        padding: 30px 0;
        font-size: 40px;
        width: 50%;
    }

    .information-partner-title {
        font-size: 95px;
    }

    .information-partner-text {
        font-size: 25px;
    }

    .logos-partner {
        width: 40%;
    }

    .cnt_information-partner-background {
        flex-direction: row;
    }

    .subtitle-information-partner-background {
        font-size: 44px;
        color: white;
        width: 70%;
        text-align: center;
    }

    .cnt_button-partner {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000000CC;
        height: 150px;
    }

    .button-partner {
        padding: 10px 60px;
        margin: 30px;
        font-size: 43px;
    }
}