.cnt_advice {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 0;
    overflow: hidden;
}

.just-moty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cnt_first-background {
    background-image: linear-gradient(
    180deg,
    black,
    #0e0e0e
    );
    width: 100%;
    height: 150px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.cnt_first-background-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 120px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.background-title {
    color: white;
    text-align: center;
    font-size: 24px;
    width: 80%;
    letter-spacing: -0.06em;
    margin: 0;
}

.size-title {
    font-size: 40px;
    font-weight: 900;
}

.cnt_advice-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('../../assets/images/Marbel-Texture.jpg');
    width: 100%;
}

.title-advice {
    text-align: center;
    font-weight: 900;
    letter-spacing: -0.06em;
    font-size: 30px;
    width: 70%;
    font-family: 'Roboto Condensed', sans-serif;
    margin: 40px 0;
}

.subtitle-advice {
    font-size: 23px;
    margin: 10px 0;
    letter-spacing: -0.06em;
    color: white;
}

.advice-card-information {
    width: 70%;
    background-color: white;
    margin-top: -30px;
    padding: 50px;
    border-top: 10px solid var(--red-prim);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    border-radius: 10px;
}

.advice-card-title {
    font-size: 30px;
    letter-spacing: -0.07em;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
}

.man-of-the-year {
    background-image: url('../../assets/images/Marbel-Texture.jpg');
    width: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;
    height: 700px;
    border-bottom:5px solid var(--orange-prim);
}

.cnt_moty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.moty {
    width: 300px;
    border: 3px solid var(--orange-prim);
    margin-bottom: 20px;
}

.size {
    padding: 10px 15px;
}

.moty-text {
    text-align: center;
    font-size: 48px;
    letter-spacing: -0.06em;
    line-height: 0.9em;
    font-weight: 800;
    margin: 0;
    padding: 0;
}

.links-decoration {
    color: var(--orange-prim);
    text-decoration: underline;
    font-style: italic;
}

@media screen and (min-width: 1650px) {

    .cnt_first-background {
        height: 450px;
    }

    .background-title {
        font-size: 34px;
    }

    .size-title {
        font-size: 90px;
    }

    .cnt_first-background-image {
        height: 750px;
    }

    .title-advice {
        text-align: center;
        font-weight: 900;
        letter-spacing: -0.06em;
        font-size: 80px;
    }

    .subtitle-advice {
        font-size: 23px;
        letter-spacing: -0.06em;
    }

    .advice-card-information {
        width: 60%;
    }

    .advice-card-title {
        font-size: 55px;
    }

    .man-of-the-year {
        height: auto;
        padding-top: 50px;
    }

    .moty {
        width: 600px;
        border: 3px solid var(--orange-prim);
        margin-bottom: 20px;
    }

    .size {
        padding: 10px 15px;
    }

    .moty-text {
        font-size: 88px;
    }

    .advice-card-text {
        font-size: 28px;
    }
}

@media screen and (min-width: 900px) and (max-width:1649px) {

    .cnt_first-background {
        height: 250px;
    }

    .background-title {
        font-size: 34px;
    }

    .size-title {
        font-size: 90px;
    }

    .cnt_first-background-image {
        height: 550px;
    }

    .title-advice {
        text-align: center;
        font-weight: 900;
        letter-spacing: -0.06em;
        font-size: 80px;
    }

    .subtitle-advice {
        font-size: 43px;
        letter-spacing: -0.06em;
    }

    .advice-card-information {
        width: 60%;
    }

    .advice-card-title {
        font-size: 55px;
    }

    .man-of-the-year {
        height: auto;
        padding-top: 50px;
    }

    .moty {
        width: 600px;
        border: 3px solid var(--orange-prim);
        margin-bottom: 20px;
    }

    .size {
        padding: 10px 15px;
    }

    .moty-text {
        font-size: 88px;
    }

    .advice-card-text {
        font-size: 28px;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .cnt_first-background-image {
        height: 200px;
    }
}