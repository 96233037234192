.cnt_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
    background-color: black;
}

.list {
    margin: 0;
    padding: 0;
    color: white;
}

.item {
    margin: 10px 0;
}

.underline {
    text-decoration: underline;
    font-weight: bold;
}