.cnt_guests {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    color: white;
}

.guests-title {
    text-align: center;
    letter-spacing: -0.06em;
    font-size: 45px;
    font-weight: 900;
}

.guests-text {
    text-align: justify;
    width: 80%;
    font-size: 25px;
}

.background {
    height: 800px;
    background-image: url('https://res.cloudinary.com/dm9pjru06/image/upload/v1725567786/guestsback_hcmwrv.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-top: 5px solid var(--orange-prim);
    margin: 0;
}

.card-guess-podcast {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.cnt_card-text-guess {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card-image-guess {
    width: 30%;
    text-align: center;
}

.card-name-guests {
    color: white;
    font-size: 25px;
    margin: 0;
}

.card-text-guests {
    color: white;
    font-size: 19px;
    width: 50%;
}

@media screen and (min-width: 1000px) {

    .guests-title {
        text-align: center;
        letter-spacing: -0.06em;
        font-size: 95px;
        font-weight: 900;
    }

    .guests-text {
        text-align: justify;
        width: 80%;
        font-size: 35px;
    }

    .background {
        height: 1800px;
    }

    .card-image-guess {
        width: 35%;
        text-align: center;
    }

    .card-name-guests {
        color: white;
        font-size: 45px;
    }

    .card-text-guests {
        color: white;
        font-size: 30px;
    }
}