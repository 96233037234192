@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.cnt_welcome-background {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    flex-direction: column;
    z-index: 2;
    background-image: linear-gradient(180deg,
    black,
    rgb(59, 59, 59));
    height: auto;
    font-family: 'Raleway', sans-serif;
    margin-top: -5px;
}

.background-video {
    display: none;
}

.welcome-init-title {
    color: white;
    font-size: 22px;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: -0.05em;
    width: 95%;
    margin: 10px 0;
    font-family: "Roboto Condensed", Sans-serif;
}

.welcome-title {
    color: white;
    font-family: "Roboto Condensed", Sans-serif;
    font-size: 70px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1em;
    width: 90%;
    margin: 10px 0;
    letter-spacing: -0.03em;
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.fixed-text {
    color: white;
    font-size: 20px;
    font-weight: 900;
    font-family: 'Roboto Condensed', sans-serif;
}

.carousel {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: -25px;
    height: 40px;
}

.word-props {
    position: absolute;
    width: 100%;
    text-align: center;
    transition: opacity 0.7s ease-in-out;
    color: #D2A164;
    font-size: 30px;
    margin: 0;
    font-weight: 900;
    font-family: 'Roboto Condensed';
}

.carousel .animated {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-align: center;
}

.additional-info {
    color: white;
    width: 94%;
}

.cnt_arrows {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: auto;
}

.scarrow {
    transform: scaleX(-1);
    /* Voltea la imagen en modo espejo */
}

.yellow-arrows {
    display: none;
    width: 0%;
}

.button-contact {
    background-color: var(--red-prim);
    border: 2px solid #841A13;
    padding: 15px 40px;
    font-size: 19px;
    font-weight: 500;
    border-radius: 10px;
    margin-top: 0;
    margin-bottom: 1.5em;
    color: white;
    transition: all 500ms ease;
    font-weight: bold;
    letter-spacing: -0.04em;
}

.backgroundoff {
    background-color: transparent;
    border: 0;
    font-weight: 200;
}

.button-contact:hover {
    cursor: pointer;
    background-color: transparent;
}

.logos {
    width: 100%;
}

.ft {
    color: white;
}

.cnt_counter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.cnt_counter-info-numbers-all {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.cnt_counter-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 30px;
}

.counter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 160px;
    height: 120px;
}

.counter-number {
    color: var(--orange-prim);
    font-family: "Roboto Condensed", sans-serif;
    font-size: 55px;
    font-weight: 900;
}

.counter-text {
    margin: 0;
    font-size: 17px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
}

.counter-text-info {
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    width: 94%;
    letter-spacing: -0.04em;
    font-size: 21px;
    margin: 0 30px;
}

.youtube-player {
    width: 100%;
    height: 550px;
}

.cnt_iframe {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 600px;
    width: 90%;
}

.cnt_news {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}

.news-title {
    font-size: 45px;
    font-weight: 900;
    letter-spacing: -0.04em;
    text-align: center;
}

.news-card {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    text-align: left;
    padding: 0;
    width: 100%;
    height: 420px;
    padding-bottom: 50px;
    transition: all 500ms ease;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}


.background-option {
    background-image: url('../../assets/images/background-op-2.webp');
}

.background-option-2 {
    background-image: url('../../assets/images/background-op-1.webp');
}

.background-option-3 {
    background-image: url('../../assets/images/background-op-3.webp');
}

.background-option-4 {
    background-image: url('../../assets/images/background-options.webp');
}

.news-card:hover {
    transform: scale(1.1);
}

.news_card-title {
    width: 85%;
    color: white;
    text-shadow: black 5px 2px 3px;
    margin: 0;
}

.news_card-text {
    width: 85%;
    color: white;
    margin-bottom: 30px;
    text-shadow: #292929 1px 1px 3px;
}

.cnt_podcast {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(180deg,
    white,
    gray);
}

.podcast-title {
    width: 70%;
    text-align: center;
    font-weight: 900;
    letter-spacing: -0.06em;
}

.cnt_person {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.quotation {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 70%;
}

.person {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    text-align: left;
    width: 70%;
}

.person-opinion {
    color: black;
    font-size: 18px;
    font-weight: 800;
}

.person-name {
    margin: 0;
    text-align: left;
    width: 100%;
    font-weight: 800;
}

.person-work {
    margin: 5px;
    text-align: left;
    width: 100%;
    color: gray;
}

/* Carousel.css */
.carousel-container2 {
    width: 100%;
    /* Ocupa el 100% del ancho de la pantalla */
    margin: 0 auto;
    overflow: visible;
    /* Permite que los elementos hijos se salgan del contenedor */
    position: relative;
    /* Necesario para posicionar las flechas */
    height: 420px;
    margin-top: 50px;
}

.slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: visible;
    /* Permite que los slides se salgan del contenedor */
}

.card-container {
    width: 100%;
    /* Asegura que la tarjeta ocupe el 100% del ancho del contenedor */
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    width: 80%;
    max-width: none;
    text-align: center;
    padding: 0;
    background: #f9f9f9;
    box-sizing: border-box;
    position: relative;
}

.cnt_card-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
    width: 100%;
}

.card-image {
    width: auto;
    height: 200px;
    background-color: black;
}

.card-title {
    margin: 0 10px;
    width: 60%;
    font-size: 1.5em;
}

.card-text {
    margin-top: 10px;
    font-size: 1em;
    color: #666;
}

/* Estilos para las flechas */
.slick-prev,
.slick-next {
    color: transparent;
    /* Elimina el color del texto */
    z-index: 1000;
    /* Asegura que las flechas estén por encima de otros elementos */
    position: absolute;
    cursor: pointer;
    /* Asegura que el botón sea clicable */
}

.slick-prev {
    left: 2%;
    /* Ajusta la posición horizontal desde el borde izquierdo del contenedor */
}

.slick-next {
    left: 93%;
    /* Ajusta la posición horizontal desde el borde derecho del contenedor */
}

.button-red {
    color: var(--red-prim);
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    font-weight: 800;
    border: 3px solid var(--red-prim);
    padding: 10px 20px;
    transition: all 500ms ease;
}

.button-red:hover {
    background-color: var(--red-prim);
    color: white;
}

.button-youtube {
    width: 450px;
    text-align: center;
    margin: 20px 50px;
    background-color: var(--red-prim);
    padding: 20px 0;
    border-radius: 10px;
    font-size: 24px;
    font-weight: 800;
}

.cnt_success-people {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title-success-people {
    width: 90%;
    text-align: center;
    font-size: 45px;
    font-weight: 900;
    letter-spacing: -0.06em;
    line-height: 0.9;
    font-family: "Roboto Condensed", Sans-serif;
}

.cnt_cards-opinions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.quotation-opinion {
    width: 50px;
    background-color: rgb(169, 169, 169);
    border: 6px solid white;
    border-radius: 50%;
    padding: 10px;
    margin-top: -20px;
}

.card-opinion {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    border: 1px solid rgb(209, 209, 209);
    box-shadow: 0 20px 20px rgb(173, 173, 173);
    margin: 40px;
}

.title-opinion {
    width: 90%;
    font-size: 21px;
    letter-spacing: -0.06em;

}

.text-opinion {
    width: 90%;
    font-size: 19px;

}

.color {
    color: var(--orange-prim);
}

.focus {
    font-weight: 900;
}

.weight {
    font-weight: 800;
}

@media screen and (min-width: 800px) {
    .counter-text-info {
        font-size: 30px;
    }

    .cnt_news-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

}

@media screen and (min-width: 1100px) {

    .cnt_welcome-background {
        height: auto;
        background-color: transparent;
    }

    .welcome-init-title {
        font-size: 35px;
        width: 85%;
        margin-bottom: -10px;
    }

    .welcome-title {
        font-size: 140px;
        width: 90%;
        margin: 30px 0;
    }

    .carousel-container {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        width: 100%;
    }

    .fixed-text {
        color: white;
        font-size: 40px;
        margin: 0 0 0 -280px
    }

    .carousel {
        position: relative;
        width: auto;
        text-align: center;
        margin-top: -1px;
        height: auto;
    }

    .word-props {
        position: absolute;
        width: 100%;
        text-align: center;
        transition: opacity 0.7s ease-in-out;
        color: var(--orange-prim);
        font-size: 40px;
        margin: 0;
        font-weight: 900;
        font-family: 'Roboto Condensed';
        margin-left: 15px;
        z-index: 0;
    }

    .additional-info {
        color: white;
        width: 94%;
        font-size: 25px;
    }

    .cnt_arrows {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 700px;
        height: auto;
    }

    .yellow-arrows {
        display: block;
        width: 100px;
    }

    .button-contact {
        font-size: 30px;
        transition: all 500ms ease;
        color: white;
    }

    .ft {
        font-size: 25px;
    }

    .cnt_counter {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .counter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 150px;
        height: 120px;
        margin-top: 80px;
    }

    .counter-number {
        font-size: 75px;
    }

    .counter-text {
        font-size: 24px;
    }

    .counter-text-info {
        font-size: 30px;
        width: 70%;
    }

    .news-title {
        font-size: 155px;
        font-weight: 900;
        letter-spacing: -0.04em;
        margin: 10px;
    }

    .cnt_news-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .news-card {
        height: 850px;
        padding-bottom: 110px;
    }

    .news_card-title {
        width: 75%;
        color: white;
        font-size: 40px;
        letter-spacing: -0.06em;
    }

    .news_card-text {
        width: 75%;
        color: white;
        margin-bottom: 50px;
        font-size: 25px;
    }

    .podcast-title {
        width: 70%;
        text-align: center;
        font-weight: 900;
        font-size: 80px;
    }

    .person-opinion {
        font-size: 40px;
    }

    .person-name {
        font-size: 30px;
    }

    .person-work {
        font-size: 25px;
    }

    /* Carousel.css */
    .carousel-container2 {
        height: 500px;
        margin-top: 100px;
        margin-bottom: 190px;
    }

    .card {
        width: 50%;
        height: 670px;
    }

    .cnt_card-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
        width: 100%;
    }

    .card-image {
        width: auto;
        height: 450px;
        background-color: black;
    }

    .card-title {
        margin-top: 10px;
        margin: 0;
        width: 60%;
        font-size: 50px;
    }

    .card-text {
        margin-top: 10px;
        font-size: 1em;
        color: #666;
    }

    .cnt_success-people {
        margin-bottom: 150px;
    }

    .title-success-people {
        font-size: 85px;
    }

    .quotation-opinion {
        width: 50px;
        background-color: rgb(169, 169, 169);
        border: 6px solid white;
        border-radius: 60%;
        padding: 10px;
        margin-top: -20px;
    }

    .card-opinion {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 500px;
        border: 1px solid rgb(209, 209, 209);
        box-shadow: 0 20px 20px rgb(173, 173, 173);
        margin: 40px;
    }

    .title-opinion {
        width: 90%;
        font-size: 51px;
    }

    .text-opinion {
        width: 90%;
        font-size: 28px;
    }
}

@media screen and (min-width: 1200px) {
    .cnt_welcome-background {
        height: auto;
        background-color: transparent;
        position: relative;
        overflow: hidden;
    }

    .background-video {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Asegura que el video cubra todo el contenedor */
        transform: translate(-50%, -50%);
        z-index: -1;
        /* Coloca el video detrás del contenido */
    }

    .cnt_welcome-background p,
    .cnt_welcome-background h1,
    .cnt_welcome-background .carousel-container,
    .cnt_welcome-background .cnt_arrows,
    .cnt_welcome-background .ft,
    .cnt_welcome-background .logos {
        position: relative;
        z-index: 1;
    }

    .cnt_iframe {
        width: 100%;
        height: 600px;
    }

    .youtube-player {
        height: 600px;
        width: 100%;
    }

    .counter {
        width: 250px;
        height: 180px;
    }

    .news-title {
        font-size: 95px;
    }
}

@media screen and (min-width: 1650px) {

    .cnt_welcome-background {
        height: auto;
        background-color: transparent;
        position: relative;
        overflow: hidden;
    }

    .background-video {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Asegura que el video cubra todo el contenedor */
        transform: translate(-50%, -50%);
        z-index: -1;
        /* Coloca el video detrás del contenido */
    }

    .cnt_welcome-background p,
    .cnt_welcome-background h1,
    .cnt_welcome-background .carousel-container,
    .cnt_welcome-background .cnt_arrows,
    .cnt_welcome-background .ft,
    .cnt_welcome-background .logos {
        position: relative;
        z-index: 1;
        /* Asegura que el contenido esté encima del video */
    }

    .welcome-init-title {
        font-size: 35px;
        width: 85%;
        margin-bottom: -10px;
    }

    .welcome-title {
        font-size: 180px;
        width: 90%;
        margin: 30px 0;
    }

    .carousel-container {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: row;
        width: 100%;
    }

    .fixed-text {
        color: white;
        font-size: 48px;
        margin: 0 0 0 -280px
    }

    .carousel {
        position: relative;
        width: auto;
        text-align: center;
        margin-top: -1px;
        height: auto;
    }

    .word-props {
        position: absolute;
        width: 100%;
        text-align: center;
        transition: opacity 0.7s ease-in-out;
        color: var(--orange-prim);
        font-size: 50px;
        margin: 0;
        font-weight: 900;
        font-family: 'Roboto Condensed';
        margin-left: 15px;
    }

    .additional-info {
        color: white;
        width: 94%;
        font-size: 25px;
    }

    .cnt_arrows {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 700px;
        height: auto;
    }

    .yellow-arrows {
        display: block;
        width: 100px;
    }

    .button-contact {
        font-size: 30px;
        transition: all 500ms ease;
    }

    .ft {
        font-size: 25px;
    }

    .cnt_counter {
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: auto;
        margin-top: 30px;
    }

    .cnt_counter-info {
        width: 80%;
        height: auto;
        flex-direction: column;
    }

    .counter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 300px;
        height: 160px;
        margin-top: 20px;
    }

    .counter-number {
        font-size: 95px;
    }

    .counter-text {
        font-size: 28px;
    }

    .counter-text-info {
        font-size: 40px;
        width: 100%;
        margin: 20px 0;
    }

    .cnt_iframe {
        width: 40%;
        height: 600px;
    }

    .youtube-player {
        height: 600px;
    }

    .news-title {
        font-size: 135px;
        font-weight: 900;
        letter-spacing: -0.04em;
        margin: 10px;
    }

    .cnt_news-cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .news-card {
        height: 850px;
        padding-bottom: 110px;
    }

    .news_card-title {
        width: 75%;
        color: white;
        font-size: 56px;
        letter-spacing: -0.06em;
    }

    .news_card-text {
        width: 75%;
        color: white;
        margin-bottom: 50px;
        font-size: 30px;
    }

    .podcast-title {
        width: 70%;
        text-align: center;
        font-weight: 900;
        font-size: 80px;
    }

    .person-opinion {
        font-size: 40px;
    }

    .person-name {
        font-size: 30px;
    }

    .person-work {
        font-size: 25px;
    }

    /* Carousel.css */
    .carousel-container2 {
        height: 500px;
        margin-top: 100px;
        margin-bottom: 190px;
    }

    .card {
        width: 50%;
        height: 670px;
    }

    .cnt_card-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
        width: 100%;
    }

    .card-image {
        width: auto;
        height: 450px;
        background-color: black;
    }

    .card-title {
        margin-top: 10px;
        margin: 0;
        width: 60%;
        font-size: 50px;
    }

    .card-text {
        margin-top: 10px;
        font-size: 1em;
        color: #666;
    }

    .cnt_success-people {
        margin-bottom: 150px;
    }

    .title-success-people {
        font-size: 85px;
    }

    .quotation-opinion {
        width: 50px;
        background-color: rgb(169, 169, 169);
        border: 6px solid white;
        border-radius: 60%;
        padding: 10px;
        margin-top: -20px;
    }

    .card-opinion {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 500px;
        border: 1px solid rgb(209, 209, 209);
        box-shadow: 0 20px 20px rgb(173, 173, 173);
        margin: 40px;
    }

    .title-opinion {
        width: 90%;
        font-size: 51px;
    }

    .text-opinion {
        width: 90%;
        font-size: 28px;
    }
}

.youtube-video-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.video-placeholder {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.play-overlay:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

.play-button {
    background-color: #ff0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.play-button:hover {
    background-color: #cc0000;
}

.youtube-player-wrapper {
    width: 100%;
    height: 100%
}

.youtube-player {
    width: 100%;
}