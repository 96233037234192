.cnt_contactNumber {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.contactNumber-title {
    font-size: 45px;
    text-align: center;
    letter-spacing: -0.06em;
    font-weight: 900;
    margin: 0;
}

.cnt_social-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 400px;
    margin: 0;
}

.cnt_text_numbers-sub {
    margin: 20px 0 0 0;
}

.image-number {
    margin-bottom: -20px;
}

.counter-number-social {
    font-size: 27vw;
    font-weight: 900;
}

.subtitle-number {
    font-size: 35px;
    margin-top: -30px;
    margin-bottom: 30px;
}

.button-numbers-text {
    color: black;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    border: 3px solid black;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 5vw;
    transition: all 300ms ease;
}

.button-numbers-text:hover {
    color: white;
    background-color: black;
}

@media screen and (min-width: 1600px) {
    .cnt_contactNumber {
        margin-top: 100px;
    }
    
    .contactNumber-title {
        font-size: 95px;
    }
    
    .cnt_social-numbers {
        margin: 40px 0;
    }

    .number {
        width: auto;
        height: 250px;
        padding: 100px;
        border-right: 2px solid black;
    }

    .bnone {
        border-right: 0;
    }
    
    .cnt_text_numbers-sub {
        margin: 0;
    }
    
    .image-number {
        margin-bottom: -20px;
        width: 130px;
    }
    
    .counter-number-social {
        font-size: 120px;
    }
    
    .subtitle-number {
        font-size: 35px;
        margin-top: -30px;
        margin-bottom: 30px;
    }
    
    .button-numbers-text {
        color: black;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        border: 3px solid black;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 30px;
    }
}

@media screen and (min-width: 1100px) {
    .cnt_contactNumber {
        margin-top: 100px;
    }
    
    .contactNumber-title {
        font-size: 95px;
    }
    
    .cnt_social-numbers {
        margin: 40px 0;
    }

    .number {
        width: auto;
        height: 250px;
        padding: 80px;
    }

    .bnone {
        border-right: 0;
    }
    
    .cnt_text_numbers-sub {
        margin: 0;
    }
    
    .image-number {
        margin-bottom: -20px;
        width: 130px;
    }
    
    .counter-number-social {
        font-size: 120px;
    }
    
    .subtitle-number {
        font-size: 35px;
        margin-top: -30px;
        margin-bottom: 30px;
    }
    
    .button-numbers-text {
        color: black;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        border: 3px solid black;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 30px;
    }
}

@media screen and (min-width: 500px) {
    .cnt_contactNumber {
        margin-top: 100px;
    }
    
    .contactNumber-title {
        font-size: 95px;
    }
    
    .cnt_social-numbers {
        margin: 40px 0;
    }

    .number {
        width: auto;
        height: 250px;
        padding: 40px;
        margin-bottom: 40px;
    }

    .bnone {
        border-right: 0;
    }
    
    .cnt_text_numbers-sub {
        margin: 0;
    }
    
    .image-number {
        margin-bottom: -20px;
        width: 130px;
    }
    
    .counter-number-social {
        font-size: 120px;
    }
    
    .subtitle-number {
        font-size: 35px;
        margin-top: -30px;
        margin-bottom: 30px;
    }
    
    .button-numbers-text {
        color: black;
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        border: 3px solid black;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 30px;
    }
}