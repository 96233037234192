.cnt_training-banner {
    text-align: center;
    background-color: black;
    width: 100%;
    height: 140px;
}

.cnt_training-information {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.training-banner-text {
    margin: 0;
    color: white;
}

.training-image {
    width:  80%;
    border: 5px solid var(--orange-prim);
}

.training-information-title {
    font-size: 40px;
    text-align: center;
    letter-spacing: -0.06em;
}

.training-information-text {
    width: 80%;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
}

.card-training-title {
    color: white;
    letter-spacing: -0.06em;
    font-weight: 800;
    font-size: 25px;
}

.card-training-text {
    color: white;
    font-size: 20px;
}

.card-training-name {
    margin: 0;
    font-size: 20px;
    color: white;
}

.card-training-who {
    margin-bottom: 30px;
    margin-left: 10px;
    margin-top: 0;
    font-size: 15px;
    color: rgb(183, 183, 183);
}

@media screen and (min-width: 1000px) {
    .cnt_training-banner {
        height: 240px;
    }
    
    .training-banner-text {
        padding: 20px 0;
        color: white;
        font-size: 80px;
    }
    
    .training-information-title {
        font-size: 90px;
    }
    
    .training-information-text {
        width: 80%;
        font-size: 25px;
    }
    
    .card-training-title {
        color: white;
        letter-spacing: -0.06em;
        font-weight: 800;
        font-size: 25px;
    }
    
    .card-training-text {
        color: white;
        font-size: 20px;
    }
    
    .card-training-name {
        margin: 0;
        font-size: 20px;
        color: white;
    }
    
    .card-training-who {
        margin-bottom: 30px;
        margin-left: 10px;
        margin-top: 0;
        font-size: 15px;
        color: rgb(183, 183, 183);
    }
}