.investment-landing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.form-section {
    background-color: var(--dark-surface);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}

.services-section {
    background-color: var(--dark-bg);
    padding: 2rem;
    overflow-y: auto;
    height: 100vh;
}

.form-title {
    font-size: clamp(2rem, 5vw, 4.5rem);
    color: var(--text-primary);
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 900;
    letter-spacing: -0.04em;
}

.form-subtitle {
    color: var(--text-secondary);
    text-align: center;
    margin-bottom: 2rem;
}

.investment-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-input {
    background-color: var(--dark-surface);
    border: 1px solid var(--text-secondary);
    color: var(--text-primary);
    padding: 0.75rem;
    border-radius: 8px;
    transition: border-color 0.3s ease;
}

.form-input:focus {
    outline: none;
    border-color: var(--orange-prim);
}

.submit-button {
    background-color: var(--orange-prim);
    color: var(--dark-bg);
    border: none;
    padding: 0.75rem;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--red-prim);
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.service-card {
    background-color: var(--dark-surface);
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: scale(1.05);
}

.service-title {
    color: var(--orange-prim);
    margin-bottom: 0.5rem;
}

.service-description {
    color: var(--text-secondary);
}

@media (max-width: 1024px) {
    .investment-landing {
        grid-template-columns: 1fr;
    }

    .form-section,
    .services-section {
        height: auto;
        overflow-y: visible;
    }
}

@media (max-width: 768px) {
    .form-title {
        font-size: 2rem;
    }

    .services-grid {
        grid-template-columns: 1fr;
    }
}