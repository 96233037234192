.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    font-family: 'Raleway', sans-serif;
    position: relative;
    height: 130px;
    padding: 0 30px;
}

.logo {
    width: 50px;
}

.nav-title {
    font-size: 75px;
    font-weight: 600;
    font-family: 'Roboto Condensed', sans-serif;
    margin-left: 10px;
    letter-spacing: -0.06em;
}

.menu-container {
    display: flex;
    align-items: center;
    height: 100px;
}

.hamburger {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.bar {
    width: 100%;
    height: 2px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
}

.menu {
    display: flex;
    list-style: none;
    overflow: auto;
    z-index: 5;
    transition: max-height 0.5s ease-in-out;
}

.menu-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all 500ms ease;
    margin-right: 50px;
    padding: 19px 10px;
    font-weight: 700;
}

.menu-item:hover {
    background-color: var(--orange-prim);
}

.link-label {
    font-size: 25px;
    color: white;
    text-decoration: none;
}

/* Modificación para submenús en desktop */
@media screen and (min-width: 769px) {

    .logo {
        width: 70px;
    }

    .sub-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-in-out, padding 0.4s ease-in-out;
        position: absolute;
        top: 120px;
        background-color: #D2A164;
        list-style: none;
        padding: 0;
        font-weight: 700;
        opacity: 0;
        visibility: hidden;
        width: 0;
    }

    .cnt_items-menu-sub:hover .sub-menu {
        max-height: 500px;
        width: 330px;
        opacity: 1;
        visibility: visible;
        padding: 10px;
    }

    /* Desactivar comportamiento de click en desktop */
    .sub-menu.open {
        max-height: 0;
        width: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
    }
}

.arrow {
    display: inline-block;
    margin-left: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(-45deg);
}

.arrow.open {
    transform: rotate(45deg);
}

.sub-menu li {
    margin: 10px 30px;
    font-size: 20px;
}

a {
    text-decoration: none;
    color: white;
}

ul {
    list-style: none;
}

@media screen and (min-width: 769px) and (max-width: 1600px) {
    .nav-title {
        font-size: 35px;
        margin-left: 0px;
    }

    .link-label {
        font-size: 16px;
    }

    .sub-menu {
        margin-top: 0px;
    }
}

/* Estilos específicos para móvil */
@media screen and (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .nav-title {
        font-size: 55px;
        margin-left: 0;
    }

    .menu {
        display: none;
        flex-direction: column;
        background-color: #D2A164;
        position: absolute;
        top: 100px;
        right: 0;
        width: 95%;
        padding: 40px;
        transition: max-height 0.5s ease-in-out;
    }

    .menu.open {
        display: flex;
        width: 85%;
    }

    .menu-item {
        margin-right: 0;
        height: 50px;
        padding: 20px 0;
    }

    .cnt_items-menu-sub {
        width: auto;
    }

    .sub-menu {
        max-height: 0;
        overflow: hidden;
        position: static;
        background-color: transparent;
        transition: max-height 0.5s ease-in-out;
        padding: 0;
    }

    .menu-item:hover .sub-menu,
    .sub-menu.open {
        max-height: 500px;
        width: 350px;
        padding: 10px 0;
    }

    .item-menu-sub {
        height: 80px;
        margin: 10px 0;
        overflow: auto;
    }

    .sub-menu li {
        margin: -10px 0;
        width: 350px;
        font-size: 20px;
    }
}