.cnt_background-podcast {
    margin: 0;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.title-podcast-background {
    margin: 0;
    color: white;
    font-size: 50px;
}

.subtitle-podcast-background {
    color: white;
    margin: 0;
}

.background-first-cnt {
    text-align: right;
}

.cnt_text-podcast {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 40px;
}

.title-podcast {
    width: 90%;
    font-weight: 800;
    letter-spacing: -0.04em;
    font-size: 35px;
}

.text-podcast {
    width: 70%;
    text-align: justify;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
}

.cnt_podcast-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.card-podcast-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(236, 236, 236);
    width: 300px;
    margin: 30px;
}

.card-image-podcast-videos {
    background-color: black;
    width: 100%;
    height: auto;
}

.card-title-podcast-videos {
    font-size: 30px;
    margin: 0;
    text-align: center;
    letter-spacing: -0.05em;
    width: 80%;
    line-height: 1em;
}

@media screen and (min-width: 1000px) {
    .cnt_background-podcast {
        height: 380px;
    }
    
    .title-podcast-background {
        font-size: 90px;
    }
    
    .subtitle-podcast-background {
        font-size: 45px;
    }
    
    .cnt_text-podcast {
        margin-bottom: 60px;
    }
    
    .title-podcast {
        width: 90%;
        font-weight: 800;
        letter-spacing: -0.04em;
        font-size: 85px;
    }
    
    .text-podcast {
        width: 70%;
        text-align: justify;
        font-size: 28px;
    }
    
    .cnt_podcast-videos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    
    .card-podcast-videos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgb(236, 236, 236);
        width: 300px;
        margin: 30px;
    }
    
    .card-image-podcast-videos {
        background-color: black;
        width: 100%;
        height: auto;
    }
    
    .card-title-podcast-videos {
        font-size: 30px;
        margin: 0;
        text-align: center;
        letter-spacing: -0.05em;
        width: 80%;
        line-height: 1em;
    }
}