@import "@fontsource/raleway/index.css";
@import '@fontsource/roboto-condensed';
@import '@fontsource/roboto-condensed/400.css';
@import '@fontsource/roboto-condensed/600.css';
@import '@fontsource/roboto-condensed/700.css';
@import '@fontsource/roboto-condensed/800.css';
@import '@fontsource/roboto-condensed/900.css';

:root {
  --orange-prim: #D2A164;
  --red-prim: #A92219;
  --dark-bg: #121212;
  --dark-surface: #1E1E1E;
  --text-primary: #E0E0E0;
  --text-secondary: #A0A0A0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Condensed', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
